import { ReactNode, useState } from 'react'
import './main.css'
import MainHeader from './components/MainHeader'
import MainFooter from './components/MainFooter'
import ImageMainStarBackground from '@/assets/images/main-star-background.svg'
import { Button, Dialog, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

interface LayoutProps {
  children: ReactNode
}
const Main = ({ children }: LayoutProps) => {
  const [isOpenMigrationTgDialog, setIsOpenMigrationDialog] = useState(true)

  return (
    <div className="main-layout">
      <div className="root-container">
        <MainHeader />
        <img
          className="root-background-fix-image"
          src={ImageMainStarBackground}
          alt="background"
        />
        <div className="root-content-container text-center">
          <div className="main-container">{children}</div>
          <MainFooter />
        </div>
      </div>
      {/* just for migration alert time */}
      <Dialog
        open={isOpenMigrationTgDialog}
        onClose={() => setIsOpenMigrationDialog(false)}
        PaperProps={{
          style: {
            padding: '24px 32px',
            width: '480px',
            background: '#24262A',
            borderRadius: '16px',
          },
        }}
      >
        <IconButton
          aria-label="close"
          onClick={() => setIsOpenMigrationDialog(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <div style={{ color: '#cccccc' }}>
          <div
            className="text-16px font-bold"
            style={{ color: 'rgb(255, 229, 0)' }}
          >
            Important Notice:
            <br />
            <span className="text-24px">ARBOT Token Migration</span>
          </div>
          <div className="mt-24px text-14px font-medium">
            Dear ARBOT holder/staker,
            <br />
            <br />
            Our team has decided to migrate ARBOT to a new token that better
            aligns with our future business needs. The migration begins on
            October 17th. Please head to our migration page to proceed with the
            migration!
            <br />
            <br />
            The staking rewards will be transferred to the migration pool, and
            the current staking rewards will stop being distributed. Please
            migrate your ARBOT tokens to the migration pool immediately to
            continue receiving reward earnings.
            <br />
            <br />
            Please note that the new token has not yet been launched. We will
            announce the TGE date of the new token in further announcement.
          </div>
          <div className="mt-24px flex justify-center">
            <a
              className="rounded-8px"
              style={{ backgroundColor: '#5E93DB', padding: '12px 24px' }}
              href="https://alpharadar.ai/migration"
              target="_blank"
              rel="noreferrer"
            >
              <div className="flex">
                <div className="font-bold text-18px">Go to Migration Page</div>
              </div>
            </a>
          </div>
        </div>
      </Dialog>
    </div>
  )
}
export default Main
